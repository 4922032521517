<template>
  <div style="margin-top: 2rem;">
    <Main style="margin-top: 2rem;">
    <router-link to="/my-events/index">
      <Button class="btn-return title-normal mt-1">
        <img :src="require(`@/static/img_events/left-arrow.png`)" alt="logo" height="15" width="15" class="mr-1 ml-2" />
        <span>Regresar</span>
      </Button>
    </router-link>
        <a-row class="mt-2">
          <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24" style="padding: 1rem">
            <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="state.loading">
              <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
            </a-col>
            <sdCards v-show="!state.loading">
              <a-row>
                  <a-col class="mt-2" :xxl="13" :lg="13" :md="13" :sm="24" :xs="24">
                      <h3 class="title-bold-normal">{{state.event_name}}</h3>
                  </a-col>
              </a-row>
              <a-row>
                  <a-col :xxl="24" :lg="24" :md="24" :sm="24" :xs="24">
                      <DataTable
                          :columns="assistanceTableColumns"
                          :data="state.assistances"
                          :totalRecords="state.totalRecords"
                          v-on:getCurrentPaginator="getDataFromPaginatorNumber"
                      />
                  </a-col>
              </a-row>
            </sdCards>
          </a-col>
        </a-row>
    </Main>
  </div>
</template>

<script>
import { Main } from '../styled';
import { useStore } from 'vuex';
import { reactive, defineAsyncComponent,  h, computed } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { getSubeventsList } from '@/services/attendanceRecord';

const DataTable = defineAsyncComponent(() => import('../components/DataTable/DataTable.vue'));
import { formatDate } from '@/utility/utility';

const assistanceTableColumns = [
    {
        title: 'Nombre del evento',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Fecha de inicio',
        dataIndex: 'event_start_date',
        key: 'event_start_date',
    },
    {
        title: 'Fecha de finalización',
        dataIndex: 'event_end_date',
        key: 'event_end_date',
    },
    {
        title: 'Acciones',
        dataIndex: 'actions',
        key: 'actions',
    },
];

export default {
  name: 'ListAssistance',
  components: {
    Main,
    DataTable
  },
  async setup() {
    const iconActions = require('@/static/img_events/SEJ_Eventos_opciones-21-2.svg');
    const iconShowForm = require('@/static/img_events/Eventos_ver_participantes_azul.svg');
    const router = useRouter();
    const { dispatch } = useStore();
    dispatch('changeTitleHeader', "Lista de asistencia");
    const eventId = useRoute().params.id
    
    const getAssistance = async (page = 1) => {
      state.loading = true;
      const response = await getSubeventsList(eventId, page);
      if (response.success) {
        state.event_name = response.event.title;
        state.assistances = response.data.data.map(subevent => {
          return {
            event_end_date: formatDate(subevent.event_end_date),
            name: subevent.title,
            event_start_date: formatDate(subevent.event_start_date),
            actions: computed ( () => 
              <sdDropdown
                action="click"
                class="dropdown-participants"
                overlay={(h(
                    'div',
                    {
                        class: 'dropdown-actions',
                        style: { cursor: 'pointer'}
                    },
                    [
                        h(
                        'button',
                        {
                            onClick: () => sendViewForm(subevent.id),
                            class: 'btn-dropdown',
                            style: { backgroundColor: 'white', border: 'none', cursor: 'pointer', color: 'gray'} 
                        },
                        [
                            h(
                            'img',
                            {
                                class: 'btn-dropdown_logo',
                                src: iconShowForm,
                                style: { width: '15px', marginRight: '0.5rem'}
                            }
                            ),
                            'Ver participantes'
                        ]
                        ),
                    ]
                    ))
                }
              >
                <img class="btn-dropdown-actions" style="width: 32px;" src={iconActions} alt="Acciones" />
              </sdDropdown>
            )
          }
        });
        state.totalRecords = response.data.total;
      }
      state.loading = false;
    }

    const sendViewForm = (id) => {
        router.push({path: `/register-assistance/subevent/assistance/${id}/${eventId}`});
    }

    const state = reactive({
        searchText: "",
        showTableByRequest: true,
        assistances: [],
        totalRecords: 0,
        tableLoading: false,
        event_name: "",
        loading: true,
        reportLoading: false,
    })

    const getDataFromPaginatorNumber = (number) => {
      getAssistance(number);
    }

    getAssistance();

    return {
      state,
      assistanceTableColumns,
      getDataFromPaginatorNumber,
    };
  },
};
</script>
<style scoped>
.input-field {
  border-radius: 30px;
}
.btn-add-event {
  background: #e70e4c;
  color: #fff;
  border-radius: 20px;
  border: none;
  padding: 7px;
  cursor: pointer;
}
.btn-dropdown-actions {
  width: 32px;
}
.btn-dropdown-actions:hover {
  cursor: pointer;
}
.dropdown-actions {
  display: flex;
  flex-direction: column;
}
.dropdown-actions .btn-dropdown {
  background-color: transparent;
  border: none;
  text-align: start;
  margin-bottom: 4px;
}
.dropdown-actions .btn-dropdown:hover {
  cursor: pointer;
}
.dropdown-actions .btn-dropdown .btn-dropdown_logo {
  width: 14px;
  margin-right: 8px;
  margin-left: 4px;
}
.dropdown-participants {
  border: 1px solid #CCCCCC;
  box-shadow: 0px 3px 6px #00000029 !important;
  padding: 8px !important;
}
.btn-return {
  background: none;
  border: none;
  color: #7b868c;
  cursor: pointer !important;
}
</style>
